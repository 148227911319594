import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { playlistService } from "../../services/playlist.service.ts";
import { videoService } from "../../services/video.service.ts";
import { userService } from "../../services/user.service.ts";
// import { useNavigate } from "react-router-dom";
// import AdBanner from "../../components/AdBanner/AdBanner";
import ListVideoComponent from "../../components/ListVideoComponent/ListVideoComponent";
import FilterList from "../../components/FilterList/FilterList.jsx";
import "./styles.scss";
import { notifySuccess, showSubscription } from "../../actions/global.action";

const LandingPage = () => {
  /*
  This component is the main entry point for the user's landing page.
  It fetches and displays videos and playlists in different categories,
  such as popular categories, popular vidoes, and user watch history.

  Notes:
  - isSkeleton: a parameter that is used to display a skeleton loading animation while the component is loading.
  */

  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [popularVideos, setPopularVideos] = useState([]);
  const [publicPlaylists, setPublicPlaylists] = useState([]);
  const [watchHistory, setWatchHistory] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const [filteredCategories, setFilteredCategories] = useState(["All"]);
  const [categoriesCount, setCategoriesCount] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!user) return;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const scenario = urlParams.get("source");
    const notSubscribed = user.subscription_status == "inactive";
    if (scenario && scenario == "success_signup" && notSubscribed) {
      setTimeout(() => {
        dispatch(showSubscription());
      }, 2000);
    }
    if (scenario && scenario == "success_login") {
      dispatch(notifySuccess("Login successfully"));
    }
  }, [user]);

  const updateCategoryCounts = (items) => {
    setCategoriesCount((prevCounts) => {
      const newCounts = { ...prevCounts };

      newCounts["All"] = (newCounts["All"] || 0) + items.length;

      items.forEach((item) => {
        item.categories?.forEach((category) => {
          newCounts[category.name] = (newCounts[category.name] || 0) + 1;
        });
      });

      // console.log(newCounts);
      return newCounts;
    });
  };

  const getPopularVideos = async () => {
    try {
      const response = await videoService.getPopular();
      setPopularVideos(response);
      updateCategoryCounts(response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPublicPlaylists = async () => {
    try {
      const response = await playlistService.getPublicPlaylists();
      setPublicPlaylists(response);
      updateCategoryCounts(response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchWatchHistory = async () => {
    try {
      const response = await userService.getWatchHistory();
      let data = [];
      for (const item of response) {
        if (item.playlist) {
          const videoData = item.playlist;
          videoData.type = "playlist";
          videoData.current_video = item.video.id;
          videoData.card_type = item.video.card_type;
          // TODO: uncomment to use video's thumbnail
          // videoData.thumbnail_url = item.video.thumbnail_url;
          // videoData.thumbnail_type = item.video.video_type;
          data.push(videoData);
        } else {
          const videoData = item.video;
          videoData.type = "video";
          data.push(videoData);
        }
      }
      setWatchHistory(data);
      updateCategoryCounts(data);
    } catch (error) {
      console.error("Error fetching user watch history:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const promises = [getPopularVideos(), fetchPublicPlaylists()];
      if (isAuthenticated) {
        promises.push(fetchWatchHistory());
      }
      await Promise.all(promises);
      setIsLoading(false);
    };

    fetchData();
  }, [isAuthenticated]);

  const filterItemsByCategory = (items) => {
    if (filteredCategories.includes("All")) return items;
    return items.filter((item) =>
      item.categories?.some((category) =>
        filteredCategories.includes(category.name),
      ),
    );
  };

  const handleCategoryClick = (category) => {
    if (filteredCategories.includes(category)) {
      setFilteredCategories(["All"]);
    } else {
      setFilteredCategories([category]);
    }
  };

  const distributePlaylistsByPopularity = (playlists) => {
    if (!playlists?.length) return [];

    const sortedPlaylists = [...playlists].sort((a, b) => b.views - a.views);

    const numRows =
      sortedPlaylists.length < 12 ? 1 : sortedPlaylists.length < 18 ? 2 : 3;

    const distributedRows = sortedPlaylists.reduce(
      (rows, playlist, index) => {
        rows[index % numRows].push(playlist);
        return rows;
      },
      Array.from({ length: numRows }, () => []),
    );

    return distributedRows;
  };

  const showFakeRows = (rows = 2) => {
    return Array(rows)
      .fill()
      .map((_, index) => (
        <ListVideoComponent
          items={[]}
          heading={"Top Playlists"}
          type={"playlists"}
          isSkeleton={true}
          key_prefix={`trending-playlist-row-${index}-`}
        />
      ));
  };

  return (
    <div className="landing-page-main">
      {!isLoading && (
        <FilterList
          categories={Object.keys(categoriesCount).sort(
            (a, b) => categoriesCount[b] - categoriesCount[a],
          )}
          selectedCategories={filteredCategories}
          handleClick={handleCategoryClick}
          areCountsVisible={false}
          categoriesCount={categoriesCount}
        />
      )}
      {isLoading && showFakeRows()}
      {isAuthenticated && (
        <ListVideoComponent
          items={filterItemsByCategory(watchHistory)}
          heading="Continue Watching"
          type="mixed"
          isSkeleton={isLoading}
          key_prefix="watch-history-"
        />
      )}
      {distributePlaylistsByPopularity(
        filterItemsByCategory(publicPlaylists),
      ).map((rowPlaylists, index) => (
        <ListVideoComponent
          key={`playlist-row-${index}`}
          items={rowPlaylists}
          heading={index === 0 ? "Top Playlists" : ""}
          type={"playlists"}
          isSkeleton={isLoading}
          key_prefix={`trending-playlist-row-${index}-`}
        />
      ))}
      <ListVideoComponent
        items={filterItemsByCategory(popularVideos)}
        heading="Trending Videos"
        isSkeleton={isLoading}
        key_prefix="trending-video-"
      />
    </div>
  );
};

export default LandingPage;
