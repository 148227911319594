import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import CodeBlock from "../CodeBlock/CodeBlock";
import { useObserver } from "../../hooks/useObserver";
import { renderContent, renderMixedText } from "../../utils/editor";
import { analyticsService } from "../../services/analytics.service";
import "./styles.scss";
import correctChimeSrc from "./correct_chime.wav";
import incorrectChimeSrc from "./incorrect_chime.wav";
import flipCardSrc from "./flip_card.wav";

const correctChime = new Audio(correctChimeSrc);
const incorrectChime = new Audio(incorrectChimeSrc);
const flipCard = new Audio(flipCardSrc);

const CardComponent = React.memo(
  ({
    cardInfo = {},
    answer1,
    answer2,
    answer3,
    answer4,
    isFunctional = false,
    explanation = null,
    showCodeComponent,
    showImageComponent,
    isDetailMode = false,
    index,
    isVisible,
    handleChevron = () => {},
  }) => {
    const frontRef = useRef(null);
    const backRef = useRef(null);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [chosenAnswer, setChosenAnswer] = useState(null);
    // const isVisible = useObserver(ref);
    const [shuffledAnswers, setShuffledAnswers] = useState([]);
    const [originalIndices, setOriginalIndices] = useState([]);
    const [showFront, setShowFront] = useState(true);
    const [multipleChoiceMode, setMultipleChoiceMode] =
      useState("not-answered");
    const [delayedContent, setDelayedContent] = useState(true);
    const [showingExplanation, setShowingExplanation] = useState(false);

    useEffect(() => {
      const answers = [answer1, answer2, answer3, answer4].filter(Boolean);

      if (isFunctional) {
        // Create array of {answer, originalIndex} objects
        const answerObjects = answers.map((answer, index) => ({
          answer,
          originalIndex: index,
        }));

        // Shuffle the array
        const shuffled = [...answerObjects].sort(() => Math.random() - 0.5);

        // Extract just the answers for display
        const shuffledAnswers = shuffled.map((item) => item.answer);

        // Keep track of original indices
        const indices = shuffled.map((item) => item.originalIndex);

        setShuffledAnswers(shuffledAnswers);
        setOriginalIndices(indices);
      } else {
        setShuffledAnswers(answers);
        setOriginalIndices([0, 1, 2, 3].slice(0, answers.length));
      }
    }, [answer1, answer2, answer3, answer4, isFunctional]);

    // useEffect(() => {
    //   setIsVisible(isVisible);
    // }, [isVisible]);

    const handleResize = useCallback(() => {
      const refs = [frontRef.current, backRef.current];
      refs.forEach((ref) => {
        if (ref) {
          const height = ref.offsetHeight;
          const width = (height * 9) / 16;
          ref.style.width = `${width}px`;
          ref.style.minWidth = `${width}px`;

          const fontSize = width / 28;
          ref.style.fontSize = `${fontSize}px`;
          ref.style.padding = `${1.5 * fontSize}px`;
          ref.style.gap = `${fontSize}px`;
          ref.style.borderRadius = `${2 * fontSize}px`;

          const baseSize = 360;
          const scaleCoef = width / baseSize;
          ref.style.setProperty("--scale-coef", scaleCoef);
        }
      });
    }, []);

    useEffect(() => {
      handleResize();
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [handleResize]);

    useEffect(() => {
      handleResize();
    }, [isDetailMode, handleResize]);

    useEffect(() => {
      if (frontRef.current) {
        frontRef.current.style.setProperty("--color-schema", cardInfo.color);
      }
      if (backRef.current) {
        backRef.current.style.setProperty("--color-schema", cardInfo.color);
      }
    }, [cardInfo.color]);

    const handleClick = () => {
      if (showingExplanation) {
        setShowingExplanation(false);
        setMultipleChoiceMode("not-answered");
        setSelectedAnswer(null);
        setChosenAnswer(null);
        return;
      }

      if (selectedAnswer === null) return;

      // Get the original index of the selected answer
      const originalIndex = originalIndices[selectedAnswer];

      // Call the backend to check the answer and record the attempt
      if (cardInfo.id) {
        analyticsService
          .submitAnswer(cardInfo.id, originalIndex)
          .then((response) => {
            const isCorrect = response?.is_correct || false;
            setChosenAnswer(isCorrect);

            if (isCorrect) {
              correctChime.play();
              setMultipleChoiceMode("correct");
            } else {
              incorrectChime.play();
              setMultipleChoiceMode("incorrect");
            }
          })
          .catch((error) => {
            console.error("Error submitting answer:", error);

            // Fallback to client-side validation if API call fails
            const isCorrect = originalIndex === cardInfo.correct_answer;

            setChosenAnswer(isCorrect);

            if (isCorrect) {
              correctChime.play();
              setMultipleChoiceMode("correct");
            } else {
              incorrectChime.play();
              setMultipleChoiceMode("incorrect");
            }
          });
      }
    };

    const renderAnswers = useMemo(() => {
      return shuffledAnswers.map((answer, index) => (
        <div
          key={index}
          className={`answer ${
            selectedAnswer === index && chosenAnswer === null ? "selected " : ""
          }${
            chosenAnswer !== null &&
            answer === answer1 &&
            (chosenAnswer !== false || showingExplanation)
              ? "green"
              : selectedAnswer === index && chosenAnswer === false
                ? "red"
                : chosenAnswer !== null
                  ? "grey"
                  : ""
          }`}
          onClick={() => {
            if (chosenAnswer === null) {
              setSelectedAnswer(index);
            }
          }}
        >
          {renderMixedText({ text: answer })}
        </div>
      ));
    }, [
      shuffledAnswers,
      selectedAnswer,
      chosenAnswer,
      answer1,
      showingExplanation,
    ]);

    const formatDescription = useCallback((description) => {
      try {
        return renderContent(JSON.parse(description));
      } catch (err) {
        return renderContent(description);
      }
    }, []);

    const defineDescription = useCallback(() => {
      if (
        cardInfo.card_type?.toLowerCase() === "flashcard" &&
        !delayedContent
      ) {
        if (cardInfo.descriptionBack)
          return formatDescription(cardInfo.descriptionBack);
        else return formatDescription(cardInfo.description_back);
      } else {
        return formatDescription(cardInfo.description);
      }
    }, [cardInfo, delayedContent, formatDescription]);

    const handleFlip = useCallback(() => {
      flipCard.play();
      setShowFront(!showFront);

      setTimeout(() => {
        setDelayedContent(!delayedContent);
      }, 300);

      // Record the flip event for analytics
      if (cardInfo.id && cardInfo.card_type?.toLowerCase() === "flashcard") {
        analyticsService.recordFlip(cardInfo.id).catch((error) => {
          console.error("Failed to record flip event:", error);
          // Don't affect the user experience if analytics fails
        });
      }
    }, [showFront, delayedContent, cardInfo.id, cardInfo.card_type]);

    const handleExplanationClick = useCallback(() => {
      setShowingExplanation(true);
    }, []);

    const getFrontStyles = useMemo(() => {
      if (
        cardInfo.is_image_background &&
        (cardInfo.image || cardInfo.image_url)
      ) {
        return {
          backgroundImage: `url(${cardInfo.image || cardInfo.image_url})`,
        };
      }
      return {};
    }, [cardInfo]);

    const getBackStyles = useMemo(() => {
      if (
        cardInfo.is_back_image_background &&
        (cardInfo.imageBack || cardInfo.image_url_back)
      ) {
        return {
          backgroundImage: `url(${cardInfo.imageBack || cardInfo.image_url_back})`,
        };
      }
      return {};
    }, [cardInfo]);

    return (
      <div className="card-container">
        <div className={`flipper ${!showFront ? "flipped" : ""}`}>
          <div
            ref={frontRef}
            className={`card-front ${cardInfo.is_image_background && (cardInfo.image || cardInfo.image_url) ? "has-background" : ""}`}
            style={getFrontStyles}
          >
            {showingExplanation ? (
              <div className="question">
                <div className="explanation-title">Explanation</div>
                <div className="explanation-content">
                  {cardInfo.explanation}
                </div>
              </div>
            ) : (
              <>
                <div className="question">
                  <div className="card-description">{defineDescription()}</div>
                </div>
                {(cardInfo.image || cardInfo.image_url) &&
                  showImageComponent &&
                  !cardInfo.is_image_background && (
                    <div className="image-container">
                      <img
                        src={cardInfo.image || cardInfo.image_url}
                        alt="question"
                        loading="lazy"
                      />
                    </div>
                  )}
                {cardInfo.code_string && showCodeComponent && (
                  <div className="code">
                    <CodeBlock
                      code_string={cardInfo.code_string}
                      language={cardInfo.language}
                    />
                  </div>
                )}
              </>
            )}
            {(cardInfo.card_type === "Multiple Choice" ||
              cardInfo.card_type === "assessment") && (
              <div className="explanation-answer">
                {showingExplanation ? (
                  <>
                    <div className="answers">
                      {renderAnswers}
                      <button className="show-answer" onClick={handleClick}>
                        Return to Question
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="answers">
                      {renderAnswers}
                      <div className="button-group">
                        {multipleChoiceMode === "not-answered" && (
                          <button
                            className="show-answer"
                            onClick={handleClick}
                            disabled={selectedAnswer === null}
                          >
                            Submit Answer
                          </button>
                        )}
                        {multipleChoiceMode === "correct" && (
                          <>
                            <button className="show-answer correct">
                              Correct!
                            </button>
                            {cardInfo?.explanation && (
                              <button
                                className="show-answer explanation-button correct"
                                onClick={handleExplanationClick}
                              >
                                Show Explanation
                              </button>
                            )}
                          </>
                        )}
                        {multipleChoiceMode === "incorrect" && (
                          <>
                            <button
                              className={`show-answer incorrect`}
                              onClick={() => {
                                // Reset the state to allow for a new attempt
                                setMultipleChoiceMode("not-answered");
                                setSelectedAnswer(null);
                                setChosenAnswer(null);
                              }}
                            >
                              Try Again!
                            </button>
                            {cardInfo?.explanation && (
                              <button
                                className="show-answer explanation-button incorrect"
                                onClick={handleExplanationClick}
                              >
                                Show Explanation
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            {cardInfo?.card_type?.toLowerCase() === "flashcard" && (
              <div className="explanation-answer">
                <div className="answers">
                  <button className="show-answer" onClick={handleFlip}>
                    Flip the card
                  </button>
                </div>
              </div>
            )}
          </div>
          <div
            ref={backRef}
            className={`card-back ${cardInfo.is_back_image_background && (cardInfo.imageBack || cardInfo.image_url_back) ? "has-background" : ""}`}
            style={getBackStyles}
          >
            <div className="question">
              <div className="card-description">{defineDescription()}</div>
            </div>
            {(cardInfo.imageBack || cardInfo.image_url_back) &&
              showImageComponent &&
              !cardInfo.is_back_image_background && (
                <div className="image-container">
                  <img
                    src={cardInfo.imageBack || cardInfo.image_url_back}
                    alt="question"
                    loading="lazy"
                  />
                </div>
              )}
            {cardInfo?.card_type?.toLowerCase() === "flashcard" && (
              <div className="explanation-answer">
                <div className="answers">
                  <button className="show-answer" onClick={handleFlip}>
                    Flip the card
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default CardComponent;
